import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeleteWorkOrderRequest } from './request/delete-work-order.request';
import { BaseResponse } from './response/base.response';
import { TechnicianGroupEdiWorkOrder } from './request/technician-group-edit-work-order.request';
import { CreateNewWorkOrderRequest } from './request/create-new-work-order.request';
import { CancelWorkOrderRequest } from './request/cancel-work-order.request';

const ENDPOINTS = {
  getMyWorkOrders: '/WorkOrder/GetMyWorkOrders',
  createWorkOrder: '/WorkOrder/Create',
  acceptWorkOrder: '/WorkOrder/AcceptWorkOrder',
  updateProgress: '/WorkOrder/UpdateProgress',
  manageWorkOrder: '/WorkOrder/Manage',
  approveWorkOrder: '/WorkOrder/ApproveWorkOrderFinish',
  deleteWorkOrder: '/WorkOrder/DeleteManagerWorkOrder',
  editTechnicianGroups: '/WorkOrder/UpdateTechnicianGroupOfWorkOrder',
  cancelWorkOrder: '/WorkOrder/CancelWorkOrder',
  createNewWorkOrder: '/WorkOrder/CreateNewWorkOrder',
  getTechnicianGroups: '/TechnicianGroup/Get',
  createTechnicianGroups: '/TechnicianGroup/Create',
  deleteTechnicianGroups: '/TechnicianGroup/Delete',
  getNotification: '/Notification/GetNotification',
  getNotificationCount:'/Notification/GetNotificationCount',
  seenNotification: '/Notification/SeenNotifications',
  workOrderAssign: '/WorkOrder/Assign',
  createCheckListCategory: '/CheckList/CreateCategory',
  createCheckList: '/CheckList/CreateCheckList',
  getCheckListCategories: '/CheckList/GetCategories',
  updateAdress: '/Auth/UpdateAddress',
  createAppointment: '/Appointment/Create',
  acceptAppointment: '/Appointment/Accept',
  deleteCheckList: '/CheckList/DeleteCheckList',
  deleteCheckListCategory: '/CheckList/DeleteCategory',
  getGroupedWorkOrder: '/Admin/GetGroupedWorkOrders',
  uploadFile: '/WorkOrder/UploadFileToWorkOrder',
  deleteFiles: '/WorkOrder/DeleteWorkOrderFile',
  getUsers: '/Admin/GetUsers',
  updateUser: '/Admin/UpdateUser'
};

@Injectable()
export class WorkOrderService {

  constructor(
    private http: HttpClient
  ) { }

  getMyWorkOrders(lastThiryDaysWorkOrder?: boolean): Observable<any> {
    if(lastThiryDaysWorkOrder == true) {
      return this.http
      .get(environment.apiUrl + ENDPOINTS.getMyWorkOrders + '?lastThiryDaysWorkOrder=' + lastThiryDaysWorkOrder)
      .pipe(map((result: any) => {
        return result;
      },
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })));
    } else {
      return this.http
      .get(environment.apiUrl + ENDPOINTS.getMyWorkOrders)
      .pipe(map((result: any) => {
        return result;
      },
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })));
    }

  }
  getGroupedWorkOrder(): Observable<any> {
    return this.http
      .get(environment.apiUrl + ENDPOINTS.getGroupedWorkOrder)
      .pipe(map((result: any) => {
        return result;
      },
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })));
  }

  getTechnicianGroups(): Observable<any> {
    return this.http
      .get(environment.apiUrl + ENDPOINTS.getTechnicianGroups)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      },
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })));
  }
  createWorkOrder(data): Observable<any> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.createWorkOrder, data)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }

  createTechnicianGroups(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http
      .post(environment.apiUrl + ENDPOINTS.createTechnicianGroups, data, httpOptions)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }


  deleteTechnicianGroups(id: number): Observable<any> {
    return this.http
      .delete(environment.apiUrl + ENDPOINTS.deleteTechnicianGroups, { params: new HttpParams().append("id", id.toString()) })
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }


  createCheckListCategory(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http
      .post(environment.apiUrl + ENDPOINTS.createCheckListCategory, data, httpOptions)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }

  getCheckListCategories(): Observable<any> {
    return this.http
      .get(environment.apiUrl + ENDPOINTS.getCheckListCategories)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }, catchError((err) => {
        throw new Error('error on request. Details: ' + err);
      })
      ));
  }

  createCheckList(data: null): Observable<any> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.createCheckList, data)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }, catchError((err) => {
        throw new Error('error on request. Details: ' + err);
      })

      ));
  }

  workOrderAssign(request): Observable<any> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.workOrderAssign, request)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }, catchError((err) => {
        throw new Error('error on request. Details: ' + err);
      })

      ));
  }

  manageWorkOrder(request): Observable<any> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.manageWorkOrder, request)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }

  deleteCheckList(id: number): Observable<any> {
    return this.http
      .delete(environment.apiUrl + ENDPOINTS.deleteCheckList, { params: new HttpParams().append("checkListId", id.toString()) })
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }

  deleteCheckListCategory(id: number): Observable<any> {
    return this.http
      .delete(environment.apiUrl + ENDPOINTS.deleteCheckListCategory, { params: new HttpParams().append("categoryId", id.toString()) })
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }

  getNotification(): Observable<any> {
    return this.http
      .get(environment.apiUrl + ENDPOINTS.getNotification)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }, catchError((err) => {
        throw new Error('error on request. Details: ' + err);
      })
      ));
  }

  public getNotificationCount(): Observable<any>{
    return this.http.get(environment.apiUrl + ENDPOINTS.getNotificationCount).pipe(map((res:any)=>{
      if(!res.success){
        console.log(res);
      }
      return res;
    },catchError((err)=>{
      throw new Error(`error on request. Details: ${err}`);
    })))
  }

  public getNotificationsByCount(count:number):Observable<any>{
    return this.http.post(environment.apiUrl + ENDPOINTS.getNotification,{count:count}).pipe(map((res:any)=>{
      if(!res.success){
        console.log(res);
      }
      return res;
    },catchError((err)=>{
      throw new Error(`error on request. Details: ${err}`)
    })))
  }

  seenNotification(): Observable<any> {
    return this.http
      .get(environment.apiUrl + ENDPOINTS.seenNotification)
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      },
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })));
  }

  uploadfile(id, file): Observable<any> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.uploadFile + "?workOrderId="+id,file)
      .pipe(map((result: any) => {
          if (!result.success) {
            console.log(result);
          }
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }

  deleteFilesId(id: number): Observable<any> {
    return this.http
      .delete(environment.apiUrl + ENDPOINTS.deleteFiles, {params: new HttpParams().append("fileId", id.toString())})
      .pipe(map((result: any) => {
        if (!result.success) {
          console.log(result);
        }
        return result;
      }),
      catchError((err) => {
        throw new Error('error on request. Details: ' + err);
      })
    );
  }

  getAllUsers(): Observable<any> {
    return this.http
      .get(environment.apiUrl + ENDPOINTS.getUsers)
      .pipe(map((result: any) => {
        return result;
      },
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })));
  }

  updatesUser(data): Observable<any> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.updateUser, data)
      .pipe(map((result: any) => {
          if (!result.success) {
            console.log(result);
          }
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }
  approveWorkOrder(workOrderId: number): Observable<any> {
    return this.http
      .get(environment.apiUrl + ENDPOINTS.approveWorkOrder, {params: new HttpParams().append("workOrderId", workOrderId.toString())})
      .pipe(map((result: any) => {
          if (!result.success) {
            console.log(result);
          }
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }
  deleteWorkOrder(id: DeleteWorkOrderRequest): Observable<BaseResponse> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.deleteWorkOrder, id)
      .pipe(map((result: BaseResponse) => {
          if (!result.success) {
            console.log(result);
          }
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }
  editTechnicianGroup(request: TechnicianGroupEdiWorkOrder): Observable<BaseResponse> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.editTechnicianGroups, request)
      .pipe(map((result: BaseResponse) => {
          if (!result.success) {
            console.log(result);
          }
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }
  cancelWorkOrder(request: CancelWorkOrderRequest): Observable<BaseResponse> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.cancelWorkOrder, request)
      .pipe(map((result: BaseResponse) => {
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }
  createNewWorkOrder(request: CreateNewWorkOrderRequest): Observable<BaseResponse> {
    return this.http
      .post(environment.apiUrl + ENDPOINTS.createNewWorkOrder, request)
      .pipe(map((result: BaseResponse) => {
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }
}
