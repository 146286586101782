import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public router: Router
  ) { }

  canActivate(): boolean {
    const user = localStorage.getItem('currentUser');
    if (!!user) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
