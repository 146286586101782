import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: '',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: '',
    loadChildren: () => import('../../report/report.module').then(m => m.ReportModule),
  },
  {
    path: '',
    loadChildren: () => import('../../profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: '',
    loadChildren: () => import('../../requests/requests.module').then(m => m.RequestsModule),
  },
  {
    path: '',
    loadChildren: () => import('../../create-workorder/create-workorder.module').then(m => m.CreateWorkorderModule),
  },
  {
    path: '',
    loadChildren: () => import('../../notifications/notifications.module').then(m => m.NotificationsModule),
  },
  {
    path: '',
    loadChildren: () => import('../../chat-rooms/chat-rooms.module').then(m => m.ChatRoomsModule),
  },
/*   {
    path: '',
    loadChildren: () => import('../../create-user/create-user.module').then(m => m.CreateUserModule),
  } */
];
