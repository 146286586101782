import {EventEmitter, Injectable} from '@angular/core';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@aspnet/signalr';
import {ChatMessage} from './response/chat-message';
import {environment} from '../../../environments/environment';
import {ChatRoom} from './response/chat-room';
import {Observable, Subject} from 'rxjs';
import { AppsService } from './apps.service';
import { WorkOrderService } from './work-order.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderUpdateService {
  public notificationCount = 0;
  connectionEstablished = new EventEmitter<boolean>();

  private connectionIsEstablished = false;

  public hubConnection: HubConnection;


  constructor(
    private apiService: WorkOrderService
  ) {
  }

  public createConnection() {
    if (this.hubConnection == null) {
      this.hubConnection = new HubConnectionBuilder().withUrl(environment.websocketUrl + '/dashboardHub')
        .configureLogging(LogLevel.Information)
        .build();
      this.startConnection();
    }
  }

  public async stopConnection() {
    await this.hubConnection.stop();
    this.hubConnection = null;
  }

  private startConnection() {
    this.hubConnection.start().then(() => {
      this.connectionIsEstablished = true;
      this.connectionEstablished.emit(true);
      this.registerOnServerEvents();
    }).catch(err => {
      console.log('Error while establishing connection, retrying...');
      setTimeout(() => {
        this.startConnection();
      }, 5000);
    });
  }

  private registerOnServerEvents() {
    // this.hubConnection.on('refreshDashboard', (data) => {
    //   console.log('refdash',data);
      
    //     if(data){
    //       //this.dashboard.getMyWorkOrders();
    //     }
    // });
    this.hubConnection.on('getNotificationCount', (data) => {
      console.log('getNotificationCount',data);
      if(data) {
        this.getNotificationCount();
      }
    })
  }

  public getNotificationCount () {
    this.apiService.getNotificationCount().subscribe(response => {
      if(response.success){
          this.notificationCount = response.data.count;
          console.log(this.notificationCount);
          console.log(response.data.count);
      }
  });
  }

}
