import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { AuthGuardService as AuthGuard } from './shared/services/auth-guard.service';



const appRoutes: Routes = [
  /* {
      path: '',
      redirectTo: '/dashboard/home',
      pathMatch: 'full',
  }, */
  {
    path: '',
    component: CommonLayoutComponent,
    children: CommonLayout_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: FullLayout_ROUTES
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
