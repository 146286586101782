import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './shared/services/authentication.service';
import {ChatService} from './shared/services/chat.service';
import { WorkOrderUpdateService } from './shared/services/work-order-update.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private authService: AuthenticationService, private chatService: ChatService,private workOrderUpdateService: WorkOrderUpdateService, private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('language') || 'en')
  }

  ngOnInit(): void {
    if (this.authService.getAuthenticatedUser() != null) {
      this.chatService.createConnection();
      this.workOrderUpdateService.getNotificationCount();
    }
  }
}
