import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../services/authentication.service';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { WorkOrderService } from '../../services/work-order.service';
import { interval, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WorkOrderUpdateService } from '../../services/work-order-update.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  loggedUser: any;
  notificationsList = [];
  notificationsSeen = [];
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  language:string;

  constructor(
    private themeService: ThemeConstantService,
    private authService: AuthenticationService,
    public router: Router,
    private workOrderService: WorkOrderService,
    public workOrderUpdateService: WorkOrderUpdateService
  ) { }

  async ngOnInit(): Promise<void> {
    this.language = localStorage.getItem('language') || 'en';
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    const loggedUser = localStorage.getItem('currentUser');
    this.loggedUser =  loggedUser? JSON.parse(localStorage.getItem('currentUser')) : null;
  }

  openBellNotifications(){
    this.workOrderService.getNotificationsByCount(3).subscribe(res => {
      this.notificationsList = res.data;
    })
    this.workOrderService.seenNotification().subscribe();
    this.workOrderUpdateService.notificationCount = 0;
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {    
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  toProfile(){
    this.router.navigate(['/profile']);
  }

  toNotificationDetail(item){
    console.log(item);
    this.router.navigate(['/notifications'], { state: item.workOrderId });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  formatDate(dateString: string): string {
    return moment(dateString).format('DD.MM.YYYY HH:mm');
  }
}
function reflesh() {
  throw new Error('Function not implemented.');
}

