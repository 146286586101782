import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ChatService} from './chat.service';
import {Register} from './request/register.request';
import { WorkOrderUpdateService } from './work-order-update.service';

const endpoints = {
  login: '/Auth/DashboardLogin',
  register: '/Auth/Register',
  deleteUser: '/Auth/DeleteUser'
};

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private chatService: ChatService,
    private workOrderUpdateService: WorkOrderUpdateService,
  ) {
  }

  getAuthenticatedUser(): any {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  login(payload: any): Observable<any> {
    const url = environment.apiUrl + endpoints.login;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        if (res.success) {
          localStorage.setItem('accessToken', res.data.token.accessToken);
          localStorage.setItem('refreshToken', res.data.token.refreshToken);
          localStorage.setItem('currentUser', JSON.stringify(res.data.user));
          this.chatService.createConnection();
          this.workOrderUpdateService.createConnection();
        }
        return res;
      }),
      catchError(err => throwError(err))
    );
  }

  logout(): void {
    localStorage.clear();
    this.router.navigateByUrl('/', {replaceUrl: true});
    this.chatService.stopConnection();
  }

  register(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + endpoints.register, data)
      .pipe(map((result: any) => {
          if (!result.success) {
            console.log(result);
          }
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }

  deleteUser(id: number): Observable<any> {
    return this.http
      .delete(environment.apiUrl + endpoints.deleteUser, {params: new HttpParams().append("id", id.toString())})
      .pipe(map((result: any) => {
          if (!result.success) {
            console.log(result);
          }
          return result;
        }),
        catchError((err) => {
          throw new Error('error on request. Details: ' + err);
        })
      );
  }
}
