import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { JwtInterceptor } from './interceptor/token.interceptor';
import { SearchPipe } from './pipes/search.pipe';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { ThemeConstantService } from './services/theme-constant.service';
import { WorkOrderService } from './services/work-order.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzIconModule,
    PerfectScrollbarModule,
    SearchPipe,
    NzSelectModule,
    TranslateModule
  ],
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    NzSelectModule,
    PerfectScrollbarModule,
    TranslateModule
  ],
  declarations: [
    SearchPipe
  ],
  providers: [
    ThemeConstantService,
    AuthGuardService,
    AuthenticationService,
    WorkOrderService,
    JwtInterceptor
  ]
})

export class SharedModule { }
